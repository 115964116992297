import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';
import { HeartbeatInfo } from '@services/heartbeat';

const ONLINE_LIMIT = 5 * 60 * 1000;

export interface WCUSerials {
  wcu_serial: string;
  carmenta_license_id: number;
  chartworld_id: string;
  sim_iccid: string;
}

export interface WCUVersions {
  argus_repo: string;
  web_ui: string;
  carmenta_deployment: string;
  windows_app: string;
  marinas_db: string;
}

export interface OTAStatus {
  ota_update_status: string;
  ota_status_since: string;
  last_update_result: boolean;
  last_result_time: string;
  is_updated: boolean;
}

export interface WCUInfo {
  wcu_id: number;
  boat_name: string;
  created_at: string;
  last_updated: string;
  group: string;
}

export interface WCU extends WCUInfo {
  heartbeat: HeartbeatInfo;
  serials: WCUSerials;
  versions: WCUVersions;
  ota_status: OTAStatus;
}

export interface WCUFilters {
  descending?: boolean;
  sort_by?: keyof WCU;
  boat_name?: string;
}

const DEFAULT_WCU_FILTERS: WCUFilters = {
  descending: true,
  sort_by: 'wcu_id',
  boat_name: ''
};

function filtersToUrlParams(filters: WCUFilters): URLSearchParams {
  const urlParams = new URLSearchParams();
  if (filters.descending) {
    urlParams.set('descending', filters.descending.toString());
  }
  if (filters.sort_by) {
    urlParams.set('sort_by', filters.sort_by);
  }
  if (filters.boat_name && filters.boat_name !== '') {
    urlParams.set('boat_name', filters.boat_name);
  }
  return urlParams;
}

export const isOnline = (wcu: WCUInfo): boolean => {
  return Date.now() - new Date(wcu.last_updated).getTime() <= ONLINE_LIMIT;
};

export const lastOnline = (wcu: WCUInfo): string => {
  const date = new Date(wcu.last_updated);
  const pad = (num: number): string => num.toString().padStart(2, '0');
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const isUpdated = (wcu: WCU): boolean => {
  return !!wcu.ota_status.is_updated;
};

export const get = async (wcuId: string): Promise<WCU | null> => {
  const res = await authorizedFetch(resolve('wcus/' + wcuId));
  return await res.json();
};

export const list = async (filters: WCUFilters = DEFAULT_WCU_FILTERS): Promise<WCU[]> => {
  const urlParams = filtersToUrlParams(filters);
  const res = await authorizedFetch(resolve('wcus/', urlParams));
  const wcus = await res.json();
  wcus.forEach((wcu: WCU) => (wcu.boat_name = wcu.boat_name ? wcu.boat_name : ''));
  return wcus;
};
